import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const ScrollToTop = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        // ページ遷移時にスクロール位置をリセット
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant' // 'smooth'にするとアニメーションするが、コンテンツのちらつきを防ぐため'instant'を使用
        });
    }, [location.pathname]); // パスが変更されたときのみ実行

    return children;
};

ScrollToTop.propTypes = {
    children: PropTypes.node.isRequired
};

export default ScrollToTop;