// src/components/Layout/Layout.jsx
import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Footer from './Footer';
import SEOHead from '../SEO/SEOHead';

const Layout = ({children, seo}) => (
    <>
        <SEOHead {...seo} />
        <div className="min-h-screen flex flex-col">
            <Header/>
            <main className="flex-grow max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8 py-8">
                {children}
            </main>
            <Footer/>
        </div>
    </>
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    seo: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.string,
        type: PropTypes.oneOf(['website', 'article']),
        author: PropTypes.string,
        publishedTime: PropTypes.string,
        modifiedTime: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.string)
    })
};

export default Layout;