// src/components/Layout/Footer.jsx
import React from 'react';
import {Link} from 'react-router-dom';
import {SITE_CONFIG} from '../../config/site';

const Footer = () => {
    const {quickLinks, legal} = SITE_CONFIG.navigation.footer;

    return (
        <footer className="bg-gray-50 mt-auto">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                    {/* サイト情報 */}
                    <div className="col-span-2">
                        <Link to="/" className="text-xl font-bold text-gray-900">
                            {SITE_CONFIG.name}
                        </Link>
                        <p className="mt-2 text-gray-600">
                            {SITE_CONFIG.description}
                        </p>
                    </div>

                    {/* クイックリンク */}
                    <div>
                        <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">
                            Quick Links
                        </h3>
                        <ul className="mt-4 space-y-2">
                            {quickLinks.map(item => (
                                <li key={item.path}>
                                    <Link to={item.path} className="text-gray-600 hover:text-gray-900">
                                        {item.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* 法的情報 */}
                    <div>
                        <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">
                            Legal
                        </h3>
                        <ul className="mt-4 space-y-2">
                            {legal.map(item => (
                                <li key={item.path}>
                                    <Link to={item.path} className="text-gray-600 hover:text-gray-900">
                                        {item.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                {/* コピーライト */}
                <div className="mt-8 pt-8 border-t border-gray-200">
                    <p className="text-center text-gray-500">
                        © {new Date().getFullYear()} {SITE_CONFIG.name}. All rights reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;