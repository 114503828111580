// src/components/Layout/Header.jsx
import React from 'react';
import {Link} from 'react-router-dom';
import {SITE_CONFIG} from '../../config/site';

const Header = () => {
    return (
        <header className="bg-white shadow-sm">
            <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between h-16">
                    <div className="flex">
                        <Link to="/" className="flex items-center text-xl font-bold text-gray-900">
                            {SITE_CONFIG.name}
                        </Link>
                    </div>
                    <div className="flex space-x-4">
                        {SITE_CONFIG.navigation.header.map(item => (
                            <Link
                                key={item.path}
                                to={item.path}
                                className="inline-flex items-center px-3 py-2 text-gray-600 hover:text-gray-900"
                            >
                                {item.name}
                            </Link>
                        ))}
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;