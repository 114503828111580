// src/components/SEO/SEOHead.jsx
import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet-async';
import {SITE_CONFIG, getMetaTitle} from '../../config/site';

const SEOHead = ({
                     title,
                     description,
                     image,
                     type = 'website',
                     author,
                     publishedTime,
                     modifiedTime,
                     tags
                 }) => {
    const metaTitle = getMetaTitle(title);
    const siteUrl = SITE_CONFIG.url;
    const metaDescription = description || SITE_CONFIG.meta.defaultDescription;
    const metaImage = image ? `${SITE_CONFIG.url}${image}` : `${SITE_CONFIG.url}${SITE_CONFIG.meta.defaultImage}`;

    return (
        <Helmet>
            {/* 基本的なメタタグ */}
            <title>{metaTitle}</title>
            <meta name="description" content={metaDescription}/>

            {/* フィードリンク */}
            <link
                rel="alternate"
                type="application/rss+xml"
                title="VR Craft - RSS Feed"
                href={`${siteUrl}/rss/feed.xml`}
            />
            <link
                rel="alternate"
                type="application/atom+xml"
                title="VR Craft - Atom Feed"
                href={`${siteUrl}/rss/atom.xml`}
            />
            <link
                rel="alternate"
                type="application/json"
                title="VR Craft - JSON Feed"
                href={`${siteUrl}/rss/feed.json`}
            />

            {/* OGP (Open Graph Protocol) */}
            <meta property="og:title" content={metaTitle}/>
            <meta property="og:description" content={metaDescription}/>
            <meta property="og:image" content={metaImage}/>
            <meta property="og:type" content={type}/>
            <meta property="og:url" content={siteUrl}/>
            <meta property="og:site_name" content={SITE_CONFIG.name}/>

            {/* Twitter Card */}
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content={metaTitle}/>
            <meta name="twitter:description" content={metaDescription}/>
            <meta name="twitter:image" content={SITE_CONFIG.meta.defaultImage}/>

            {/* 記事固有のメタデータ */}
            {type === 'article' && (
                <>
                    {author && <meta name="author" content={author}/>}
                    {publishedTime && <meta property="article:published_time" content={publishedTime}/>}
                    {modifiedTime && <meta property="article:modified_time" content={modifiedTime}/>}
                    {tags?.map((tag, index) => (
                        <meta key={index} property="article:tag" content={tag}/>
                    ))}
                </>
            )}

            {/* JSON-LD構造化データ */}
            <script type="application/ld+json">
                {JSON.stringify(
                    type === 'article' ? {
                        "@context": "https://schema.org",
                        "@type": "Article",
                        "headline": title,
                        "image": [SITE_CONFIG.meta.defaultImage],
                        "datePublished": publishedTime,
                        "dateModified": modifiedTime || publishedTime,
                        "author": {
                            "@type": "Person",
                            "name": author
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": "VR Craft",
                            "logo": {
                                "@type": "ImageObject",
                                "url": `${siteUrl}/logo.png`
                            }
                        },
                        "description": {metaDescription},
                        "keywords": tags?.join(", ")
                    } : {
                        "@context": "https://schema.org",
                        "@type": "WebSite",
                        "name": "VR Craft",
                        "url": siteUrl,
                        "description": {metaDescription}
                    }
                )}
            </script>
        </Helmet>
    );
};

SEOHead.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    type: PropTypes.oneOf(['website', 'article']),
    author: PropTypes.string,
    publishedTime: PropTypes.string,
    modifiedTime: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string)
};

export default SEOHead;