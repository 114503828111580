// src/App.jsx の更新（ルーティングの追加）
import React, {Suspense} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import Layout from './components/Layout/Layout';
import ScrollToTop from './components/UI/ScrollToTop';

// 遅延読み込みするページコンポーネント
const Home = React.lazy(() => import('./pages/Home'));
const Posts = React.lazy(() => import('./pages/Posts'));
const Post = React.lazy(() => import('./pages/Post'));
const About = React.lazy(() => import('./pages/About'));
const Contact = React.lazy(() => import('./pages/Contact'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'));
const Terms = React.lazy(() => import('./pages/Terms'));

const PageLoading = () => (
    <div className="flex justify-center items-center min-h-[60vh]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-600"></div>
    </div>
);

function App() {
    return (
        <HelmetProvider>
            <Router>
                <ScrollToTop>
                    <Layout>
                        <Suspense fallback={<PageLoading/>}>
                            <Routes>
                                <Route path="/" element={<Home/>}/>
                                <Route path="/posts" element={<Posts/>}/>
                                <Route path="/posts/:slug" element={<Post/>}/>
                                <Route path="/about" element={<About/>}/>
                                <Route path="/contact" element={<Contact/>}/>
                                <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                                <Route path="/terms" element={<Terms/>}/>
                                <Route path="*" element={
                                    <div className="text-center py-10">
                                        <h1 className="text-4xl font-bold mb-4">404</h1>
                                        <p>Page not found</p>
                                    </div>
                                }/>
                            </Routes>
                        </Suspense>
                    </Layout>
                </ScrollToTop>
            </Router>
        </HelmetProvider>
    );
}

export default App;