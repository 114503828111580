// src/config/site.js
export const SITE_CONFIG = {
  // サイト基本情報
  name: 'VR Craft',
  description: 'VRと3Dの技術情報を発信するブログメディア',
  url: 'https://vrcraft.space',
  email: 'contact@vrcraft.space',
  social: {
    twitter: '@vr_craft',
    github: 'vr-craft'
  },

  // メタ情報
  meta: {
    defaultTitle: 'VR Craft - VRと3Dの技術メディア',
    titleTemplate: '%s | VR Craft',
    defaultDescription: 'VRと3Dに関する情報を発信するブログサイトです。',
    defaultImage: '/default-ogp.jpg',
    locale: 'ja_JP'
  },

  // ナビゲーション項目
  navigation: {
    header: [
      { name: 'ホーム', path: '/' },
      { name: '記事一覧', path: '/posts' },
      { name: 'VR Craftについて', path: '/about' },
      { name: 'お問い合わせ', path: '/contact' }
    ],
    footer: {
      quickLinks: [
        { name: '記事一覧', path: '/posts' },
        { name: 'About', path: '/about' },
        { name: 'お問い合わせ', path: '/contact' }
      ],
      legal: [
        { name: 'プライバシーポリシー', path: '/privacy-policy' },
        { name: '利用規約', path: '/terms' }
      ]
    }
  },

  // ページ設定
  pages: {
    home: {
      title: 'ホーム',
      description: 'VRと3Dの技術情報を発信するブログメディア'
    },
    posts: {
      title: '記事一覧',
      description: 'VRと3Dに関する最新の記事一覧'
    },
    about: {
      title: 'About',
      description: 'VR Craftについて - VRと3Dの技術情報を発信するブログメディア',
      sections: {
        mission: 'ミッション',
        topics: '取り扱うトピック',
        team: '執筆チーム'
      }
    },
    contact: {
      title: 'お問い合わせ',
      description: 'VR Craftへのお問い合わせ - ご質問、フィードバック、お仕事のご依頼など',
      form: {
        name: 'お名前',
        email: 'メールアドレス',
        subject: '件名',
        message: 'メッセージ',
        submit: '送信する'
      }
    },
    privacyPolicy: {
      title: 'プライバシーポリシー',
      description: 'VR Craftのプライバシーポリシー - 個人情報の取り扱いについて',
      lastUpdated: '2024年4月1日'
    },
    terms: {
      title: '利用規約',
      description: 'VR Craftの利用規約 - サイト利用に関する規約と条件',
      lastUpdated: '2024年4月1日'
    }
  }
};

// サイト全体で使用する定数
export const CONSTANTS = {
  pagination: {
    itemsPerPage: 10
  },
  dates: {
    format: 'yyyy年M月d日'
  },
  breakpoints: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px'
  }
};

// ユーティリティ関数
export const getPageTitle = (pageName) => {
  const page = SITE_CONFIG.pages[pageName];
  return page ? page.title : '';
};

export const getPageDescription = (pageName) => {
  const page = SITE_CONFIG.pages[pageName];
  return page ? page.description : SITE_CONFIG.meta.defaultDescription;
};

export const getMetaTitle = (title) => {
  if (!title) return SITE_CONFIG.meta.defaultTitle;
  return SITE_CONFIG.meta.titleTemplate.replace('%s', title);
};
